import React from "react";
import Navbar from "../components/Navbar";
import { Outlet } from "react-router-dom";
import manager from "./../Images/manager2.jpg";
import comp from "./../Images/computer.jpg";
import Marquee from "react-fast-marquee";
import Footer from "../components/Footer";
import grace from "./../Images/gracee.jpeg";
import { IoMdArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const programs = [
  {
    id: 1,
    name: "Hotel Management",
    description:
      "Gain skills in hospitality, customer service, and hotel operations management. Perfect for those looking to lead in the hospitality industry.",
    link: "/admission/programmes",
  },
  {
    id: 2,
    name: "Graphic Design",
    description:
      "Explore creativity through design principles, digital media, and visual communication. Ideal for aspiring designers and visual artists.",
    link: "/admission/programmes",
  },
  {
    id: 3,
    name: "Business Operations",
    description:
      "Learn the essentials of business operations, including management, logistics, and organizational strategies for success.",
    link: "/admission/programmes",
  },
];

const facility = {
  id: 1,
  name: "Computer Labs",
  description:
    "Our state-of-the-art computer labs are equipped with the latest technology to support all your academic needs. With high-speed internet, modern software, and ample space, students can complete assignments, collaborate on projects, and explore the world of technology.",
  image: comp,
};

const Homepage = () => {
  return (
    <div>
      <Marquee
        className="bg-blue-900 text-white  font-sans cursor-pointer"
        pauseOnHover={true}
        speed={40}
        gradient={false}
      >
        Welcome to Grace College - Education for Excellence!
      </Marquee>
      <div className="parallax-bg bg-blue-950 h-screen flex items-center justify-center">
        <h1 className="text-white text-center text-5xl bg-transparent md:text-9xl font-serif font-bold">
          Welcome!
        </h1>
      </div>
      <div className="w-full h-full  max-w-[1024px]  mx-auto  text-black md:grid md:grid-cols-6 grid-cols-1 mt-2 flex flex-col justify-center items-center">
        <div className="w-full col-span-2 flex flex-col justify-center items-center">
          <div className="frame-custom   h-full md:w-full w-3/4 sm:w-1/2 border-gray-300 relative shadow-lg p-1 bg-white ">
            <img src={manager} alt="" className="h-72 w-full" loading="lazy"/>
          </div>
          <div className="py-1">
            Manager: <span className="font-bold">Pius Chrispine Mpora</span>
          </div>
        </div>

        <div className="relative col-span-4 p-4 h-full   flex flex-col items-center shadow-md">
          <h2 className=" relative text-2xl font-bold text-center mt-6 my-4">
            WELCOME NOTE
            <div className="underline-decor"></div>
          </h2>

          <p className="px-8">
            Welcome to Grace College, your gateway to an exceptional educational
            journey in the heart of Mbeya city! At Grace College, we believe in
            empowering students to achieve their dreams through quality
            education, a supportive community, and a vibrant campus life.
            Whether you're here to explore new horizons, develop your talents,
            or prepare for your future career, you'll find a place that feels
            like home, filled with opportunities to grow, learn, and thrive.
            Join us as we embark on a path of knowledge, innovation, and
            excellence where every day is a chance to shine.
          </p>
        </div>
      </div>
      <div className="w-full bg-blue-950 grid md:grid-cols-6 grid-cols-1">
        <div className="col-span-3 p-8">
          <img src={grace} alt="Grace college Image" loading="lazy"/>
        </div>
        <div className="col-span-3 flex flex-col text-white items-center  p-4">
          <h2 className="text-3xl py-4 font-bold ">About Our College</h2>
          <p className="text-justify px-4">
            Welcome to Grace College, located in the heart of Mbeya city. We are
            committed to providing a transformative education that empowers
            students to achieve their academic and personal goals. Our holistic
            approach combines rigorous academic programs with spiritual lessons
            that inspire personal growth and a deeper understanding of faith. At
            Grace College, we strive to create a supportive and inclusive
            environment where students can excel in their studies, explore their
            beliefs, and develop the character needed to lead with integrity and
            purpose in all aspects of life.
          </p>
          <div className="w-full flex items-start my-2  pl-4">
            <Link
              to="/about-us"
              className="flex justify-center items-center bg-blue-700 rounded-full gap-2 py-2 px-2 md:shadow-full hover:bg-blue-500 hover:text-gray-300"
            >
              Read More <IoMdArrowForward />{" "}
            </Link>
          </div>
        </div>
      </div>

      <div className="flex flex-col py-12 items-center justify-center gap-4 bg-slate-200">
        <span className="text-xl md:text-2xl text-slate-900">
          <q>Start Your Journey with Us</q>{" "}
        </span>
        <Link
          to="/admission/info"
          className="apply-now-btn text-slate-900 font-bold py-2 px-6 text-xl md:text-3xl rounded-full relative"
        >
          Apply Now
        </Link>
      </div>
      <div>
        <section className="py-12 bg-gray-50">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-8">
              Academic Programs
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {programs.map((program) => (
                <div
                  key={program.id}
                  className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow"
                >
                  <h3 className="text-xl font-semibold mb-2">{program.name}</h3>
                  <p className="text-gray-700 mb-4">{program.description}</p>
                  <Link
                    to={program.link}
                    className="text-blue-500  rounded-md px-4 py-2 hover:underline font-medium"
                  >
                    Learn More
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>

      <div>
        <section className="py-12 bg-gray-50">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-8">
              Facilities and Resources
            </h2>
            <div className="flex flex-col md:flex-row items-center bg-white rounded-lg shadow-lg overflow-hidden">
              <img
                src={facility.image}
                alt={facility.name}
                loading="lazy"
                className="w-full md:w-1/2 h-64 object-cover"
              />
              <div className="p-6 md:w-1/2">
                <h3 className="text-2xl font-semibold mb-4">{facility.name}</h3>
                <p className="text-gray-700 mb-4">{facility.description}</p>

              
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <section className="py-12 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-8">
              Location and Map
            </h2>
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 p-6">
                <h3 className="text-2xl font-semibold mb-4">
                  Grace College Location
                </h3>
                <p className="text-gray-700 mb-2">
                  Grace College is located in the heart of Mbeya City, Tanzania,
                  offering a vibrant and accessible environment for students.
                  The campus is surrounded by essential amenities, making it a
                  convenient and enriching place to study.
                </p>
              </div>
              <div className="md:w-1/2">
                <iframe
                  title="Grace College Location"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.7325301194064!2d33.45346947369905!3d-8.904439691152001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19009ff49cb73c9f%3A0x309f5ddb8990a704!2sGrace%20College!5e0!3m2!1sen!2stz!4v1726033523865!5m2!1sen!2stz" // Replace with the actual map iframe source from Google Maps
                  width="100%"
                  height="350"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Homepage;
