import React from "react";
import { IoMdArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
const Programmes = () => {
  const programmesDetails = [
    {
      id: 1,
      program: "Uongozi wa Hotel/ Hotel Management",
      duration: "9 Month",
      day: "560,000/=",
      hostel: "680,000/=",
    },
    {
      id: 2,
      program: "Uhazili/Secretarial ",
      duration: "2 years",
      day: "680,000/=",
      hostel: "900,000/=",
    },
    {
      id: 3,
      program: "Utalii/ Tour Guide ",
      duration: "2 years",
      day: "680,000/=",
      hostel: "900,000/=",
    },
    {
      id: 4,
      program: "Information Technology  ",
      duration: "2 years",
      day: "680,000/=",
      hostel: "900,000/=",
    },
    {
      id: 5,
      program: "Biashara/ Business operation ",
      duration: "2 years",
      day: "680,000/=",
      hostel: "900,000/=",
    },
    {
      id: 6,
      program: "Computer Application ",
      duration: "2-3 Month",
      day: "265,000/=",
      hostel: "325,000/=",
    },
    {
      id: 7,
      program: "Salon and Makeup ",
      duration: "3 Month",
      day: "300,000/=",
      hostel: "400,000/=",
    },
    {
      id: 8,
      program: "English and French course  ",
      duration: "3-6 Month",
      day: "165,000/=",
      hostel: "265,000/=",
    },
    {
      id: 9,
      program: "Graphics Design ",
      duration: "3 Month",
      day: "300,000/=",
      hostel: "400,000/=",
    },
    {
      id: 10,
      program: "Computer repair & maintenance ",
      duration: "3 Month",
      day: "300,000/=",
      hostel: "400,000/=",
    },
    {
      id: 11,
      program: "Decoration/ mapambo ",
      duration: "3 Month",
      day: "200,000/=",
      hostel: "250,000/=",
    },
    {
      id: 12,
      program: "Data base and computer system   ",
      duration: "3 Month",
      day: "300,000/=",
      hostel: "400,000/=",
    },
  ];
  return (
    <div>
      <div className="flex justify-center items-center w-full h-36 bg-blue-950 text-2xl md:text-4xl shadow-sm  text-center  font-serif text-[#8c7340]">
        <h2>Programmes Offered</h2>
      </div>
      <div className="grid md:grid-cols-8 grid-cols-1 ">
        <div className="col-span-6">
          <div>
            <div className="p-5  bg-slate-200">
              <h1 className="text-xl mb-2 font-serif">
                Explore Our Programmes
              </h1>
              <div className="overflow-auto shadow rounded-md">
                <table className="w-full rounded-md shadow-md font-serif">
                  <thead className="bg-blue-950 text-white border-b-2 border-gray-200 cursor-pointer">
                    <tr>
                      <th className="p-3 text-sm font-semibold tracking-wide text-center">
                        No.
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        Programme Offered
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-center">
                        DURATION
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        DAY FEE{" "}
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        HOSTEL FEE
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-100 cursor-pointer">
                    {programmesDetails.map((application, index) => (
                      <tr
                        key={application.id}
                        className={
                          index % 2 === 0 ? "bg-white" : "bg-[#f6f4f0]"
                        }
                      >
                        <td className="w-20 text-sm text-gray-700 whitespace-nowrap text-center">
                          {application.id}
                        </td>
                        <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                          {application.program}
                        </td>
                        <td className="w-24 text-sm text-gray-700 whitespace-nowrap">
                          {application.duration}
                        </td>
                        <td className="p-3 text-sm text-gray-700 font-bold">
                          {application.day}
                        </td>
                        <td className="p-3 text-sm text-gray-700 font-bold">
                          {application.hostel}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-2 font-serif p-5 bg-slate-200 shadow-md ">
          <h2 className="text-center md:text-4xl text-2xl text-[#8c7340] ">
            Admission Link
          </h2>
          <div className="flex flex-col">
            <div className="hover:translate-x-2 text-blue-950 duration-100">
              {" "}
              <Link to="/admission/info" className="flex gap-2.5 items-center">
                <IoMdArrowForward size={18} />
                How to Apply
              </Link>
            </div>
            <div className="hover:translate-x-2 text-blue-950 duration-100">
              <Link
                to="/admission/programmes"
                className="flex gap-2.5 items-center"
              >
                <IoMdArrowForward size={18} />
                Programmes Offered
              </Link>
            </div>

            <div></div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Programmes;
