import React from 'react'
import Footer from '../components/Footer';
import principals from './../staffImage/principal.png'
import ict from './../staffImage/EMMANUEL_YAMBAYAMBA.png'
import hotel from './../staffImage/GETRUDE MWALONGO.jpg'
import coordinator from './../staffImage/EZEKIA WILLY.jpg'
import tour from './../staffImage/NETTO SHONDE.jpg'


const StaffMember = ({ name, role, department, imageUrl }) => {
  const getIcon = () => {
    switch (department) {
      case 'ICT': return '💻';
      case 'Hotel Management': return '☕';
      case 'Tour Guide': return '🗺️';
      case 'Training Coordinator': return '📋';
      case 'Administration': return '⭐';
      default: return '👤';
    }
  };

  return (
    <div className={`bg-white rounded-lg shadow-md overflow-hidden ${role === 'Principal' ? 'border-4 border-red-500 w-full max-w-md mx-auto' : 'w-64'}`}>
      <div className="bg-gray-100 px-4 py-2 flex justify-between items-center">
        <h3 className="text-lg font-semibold">{name}</h3>
        <span className="text-2xl">{getIcon()}</span>
      </div>
      <div className="p-4 text-center">
        <img 
          src={imageUrl || "https://via.placeholder.com/150"} 
          loading="lazy"
          alt={`${name}'s profile`} 
          className="w-24 h-24 rounded-full mx-auto mb-3"
        />
        <p className="font-bold text-gray-800">{role}</p>
        <p className="text-sm text-gray-600">{department}</p>
      </div>
    </div>
  );
};

const DepartmentGroup = ({ department, members }) => (
  <div className="mt-8">
    <h2 className="text-2xl font-bold mb-4 text-center text-gray-700">{department}</h2>
    <div className="flex flex-wrap justify-center gap-4">
      {members.map((member, index) => (
        <StaffMember key={index} {...member} />
      ))}
    </div>
  </div>
);

const Adminstration = () => {
  const staffMembers = [
    { name: "Sir. Ezekia Willy", role: "Staff", department: "Training Coordinator", imageUrl: coordinator },
    { name: "Dr. Ibrahim U sade", role: "Principal", department: "Administration", imageUrl:principals },
    { name: "Sir. Emmanuel Yambayamba", role: "Lecturer", department: "ICT", imageUrl: ict },
    { name: "Miss. Getrude C Mwalongo", role: "Lecturer", department: "Hotel Management", imageUrl: hotel },
    { name: "Sir. Netto Lameck Shonde", role: "Lecturer", department: "Tour Guide", imageUrl: tour },

  ];

  const principal = staffMembers.find(member => member.role === 'Principal');
  const otherStaff = staffMembers.filter(member => member.role !== 'Principal');

  const departmentGroups = otherStaff.reduce((groups, member) => {
    const group = groups[member.department] || [];
    group.push(member);
    groups[member.department] = group;
    return groups;
  }, {});

  return (
    <div>
 <div className="p-6 bg-gray-50 ">
      <h1 className="text-3xl font-bold text-center mb-8 text-gray-800">Administration</h1>
      
   
      <div className="mb-12">
        <h2 className="text-2xl font-bold mb-4 text-center  text-gray-700">Principal</h2>
        <StaffMember {...principal} />
      </div>

      {/* Other Staff by Department */}
      <div className='md:flex gap-4 flex-wrap  justify-center'>
      {Object.entries(departmentGroups).map(([department, members]) => (
        <DepartmentGroup key={department} department={department} members={members} />
      ))}
      </div>
     
    </div>
    <div>
      <Footer/>
    </div>
    </div>
   
  )
}

export default Adminstration