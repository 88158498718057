import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";

import image1 from "./../AboutImage/staff1.JPG";
import image2 from "./../AboutImage/student.JPG";
import image3 from "./../AboutImage/student2.JPG";
import image4 from "./../AboutImage/student3.jpeg";
import image5 from "./../AboutImage/student4.JPG";
import image6 from "./../AboutImage/student5.jpeg";
import Footer from "../components/Footer";
import { IoMdArrowDropright } from "react-icons/io";

const AboutUs = () => {
  const images = [image3, image4, image5, image6];

  const [currentSlide, setCurrentSlide] = useState(0);
  const intervalRef = useRef(null);
  const slideshowDuration = 5000;

  //const [startAnimation, setStartAnimation] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    startSlideshow();

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const startSlideshow = () => {
    intervalRef.current = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === images.length - 1 ? 0 : prevSlide + 1
      );
    }, slideshowDuration);
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
    clearInterval(intervalRef.current);
    startSlideshow();
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === images.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? images.length - 1 : prevSlide - 1
    );
  };

  return (
    <div>
      <div className="flex justify-center flex-col items-center w-full h-36 bg-blue-950 text-2xl md:text-4xl shadow-md  text-center  font-serif text-[#8c7340]">
        <h2>About Us</h2>
        <div className="pt-4 flex text-white text-xs font-light cursor-default">
          <span className="flex justify-center items-center">
            <IoMdArrowDropright />
            <IoMdArrowDropright />
            About-us /{" "}
          </span>{" "}
          <span>
            <Link to="/" className="hover:text-blue-600">
              {" "}
              Home
            </Link>
          </span>
        </div>
      </div>
      <div className="  rounded-lg  shadow-full">
        <div className=" z-0 relative w-full" data-carousel="slide">
          <div className="relative z-10 h-72 overflow-hidden rounded-lg md:z-0  md:h-96">
            {images.map((image, index) => (
              <div
                key={index}
                className={`duration-700 ease-in-out ${
                  index === currentSlide ? "block" : "hidden"
                }`}
                data-carousel-item
              >
                <img
                  src={image}
                  loading="lazy"
                  className="absolute z-0  block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
                  alt={`Slide ${index + 1}`}
                />
              </div>
            ))}
          </div>

          <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
            {images.map((_, index) => (
              <button
                key={index}
                type="button"
                className={`w-3 h-3 rounded-full ${
                  index === currentSlide ? "bg-black" : "bg-white"
                }`}
                aria-current={index === currentSlide}
                aria-label={`Slide ${index + 1}`}
                onClick={() => goToSlide(index)}
              />
            ))}
          </div>

          <button
            type="button"
            className="absolute  top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            data-carousel-prev
            onClick={prevSlide}
          >
            <div className="border p-2 rounded-full border-blue-950 bg-blue-50 shadow ">
              <svg
                class="w-5 h-5 text-blue-950    dark:text-gray-800 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
            </div>

            <span class="sr-only">Previous</span>
          </button>

          <button
            type="button"
            className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            data-carousel-next
            onClick={nextSlide}
          >
            <div className="border p-2 rounded-full border-blue-950 bg-blue-50 shadow ">
              <svg
                class=" w-5 h-5 text-blue-950  dark:text-gray-800 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
            </div>

            <span class="sr-only">Next</span>
          </button>
        </div>
      </div>
      <div className="relative mt-2 gap-4 grid md:grid-cols-8 grid-cols-1 mx-auto ">
        <div className="  p-4 col-span-5 shadow-full border font-sans  bg-blue-100 px-4">
          <h2 className="md:text-3xl text-xl text-center pt-4 font-bold ">
            WHO WE ARE
          </h2>
          <div>
            <p>
              Grace Ministries International is an evangelical, Bible-believing
              mission with the purpose of bringing people to experience
              salvation in the Lord Jesus Christ and incorporating them into
              strong, self-sustaining churches.
            </p>
            <p>
              Two billion people have never heard the gospel and 10,000 people
              groups have no viable church;therefore, along with the evangelical
              community and more than more than 100 fellow members of the EFMA,
              we are committed to spreading the gospel of our Lord Jesus christ
              to all peoples of the World, At the present time,GMI is working in
              Australia,Bolivia,Brazil,Costa Rica,India,Philippines,Puerto
              Rico,Tanzania,United States,Uruguaya and Zaire.
            </p>
          </div>
        </div>
        <div className="relative  col-span-3 flex justify-center items-center">
          <img src={image1} alt="" className="h-72 w-full " />
        </div>
      </div>

      <div className="mt-2 md:mt-8 gap-4 md:grid md:grid-cols-8 grid-cols-1 flex  flex-col-reverse">
        <div className="relative  col-span-3 flex justify-center items-center">
          <img src={image2} alt="" className="h-72 w-full  " />
        </div>
        <div className="  p-4 col-span-5 shadow-full border font-sans  bg-blue-100">
          <h2 className="md:text-3xl text-xl text-center pt-4 font-bold ">
            {" "}
            MISSION STATEMENT
          </h2>
          <div>
            <p>
              The mission of Grace Ministries International is to send
              misssionaries into various countries of the world with the purpose
              of:
            </p>
            <p>
              <ul>
                <li className="list-disc list-inside ">
                  Preaching the gospel of God's grace so that the hearers are
                  led into a saving knowledge of the Lord Jesus Christ;
                </li>
                <li className="list-disc list-inside ">
                  establishing indigenous churches where believers grow in the
                  grace and Knowledge of our Lord Jesus Christ; and
                </li>
                <li className="list-disc list-inside ">
                  training and equipping men and women to do the work of
                  ministry in the churches.
                </li>
              </ul>
              Depending on the particular need of the field,GMI will also be
              involved in medical work,educational ministries, and community
              development
            </p>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
export default AboutUs;
