import React from "react";
import { IoMdArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
const AdmissionInfo = () => {
  return (
    <div className="w-full bg-[#f6f4f0] h-screen">
      <div className="flex justify-center items-center w-full h-36 bg-blue-950 text-2xl md:text-4xl shadow-sm  text-center  font-sans text-[#8c7340]">
        <h2>How to Apply </h2>
      </div>
      <div className="grid md:grid-cols-8 gap-2.5 grid-cols-1  my-1 font-sans bg-slate-200 ">
        <div className="col-span-6 bg-white p-2  text-justify px-6">
          <div>
            Katika Grace College, tunathamini kila hatua ya safari yako ya
            kuomba kujiunga. Ingawa kwa sasa hatuwezi kutoa maombi ya mtandaoni,
            mchakato wa kuomba ni rahisi na wa moja kwa moja. Hapa ni jinsi
            unavyoweza kuendelea:
          </div>
          <div>
            <h1 className="text-xl font-bold ">1.Pata Fomu ya Maombi</h1>
            <p className="px-4">
              Tembelea ofisi ya udahili ya Grace College iliyopo katika mtaa wa
              Forest karibu na chuo kikuu Mzumbe kampasi ya Mbeya ili kupata
              fomu ya maombi. Vinginevyo, unaweza kuomba fomu kwa kuwasiliana na
              ofisi yetu ya udahili kupitia simu au barua pepe, na tutakuongoza
              jinsi ya kuipata.
            </p>
          </div>
          <div>
            <h1 className="text-xl font-bold">2.Jaza Fomu ya Maombi</h1>
            <p className="px-4">
              Jaza kwa makini sehemu zote za fomu ya maombi. Hakikisha unatoa
              taarifa sahihi na kuambatanisha nyaraka zinazohitajika, kama vile
              nakala za vyeti vya masomo, Cheti cha kuzaliwa au kitambulisho, na
              nyaraka nyingine yoyote itakayobainishwa.
            </p>
          </div>
          <div>
            <h1 className="text-xl font-bold">3.Wasilisha Maombi Yako</h1>
            <p className="px-4">
              Baada ya kujaza fomu na kukusanya nyaraka zote zinazohitajika,
              wasilisha maombi yako kwa mkono katika ofisi ya udahili ya Grace
              College. Ikiwa huwezi kufika binafsi, unaweza pia kutuma maombi
              yako kamili kwa njia ya barua au barua pepe kwenda kwenye anwani
              iliyoonyeshwa kwenye fomu ya maombi.
            </p>
          </div>
          <div>
            <h1 className="text-xl font-bold ">4.Uhakiki wa Maombi</h1>
            <p className="px-4">
              Baada ya kuwasilisha, maombi yako yatapitiwa na timu ya udahili.
              Tafadhali hakikisha nyaraka zote zimekamilika ili kuepuka
              ucheleweshaji wowote katika mchakato wa uhakiki.
            </p>
          </div>
          <div>
            <h1 className="text-xl font-bold">5.Taarifa ya Kupata Udahili</h1>
            <p className="px-4">
              Utaarifiwa kuhusu hali ya udahili wako kupitia mawasiliano
              uliyoyatoa kwenye fomu yako ya maombi. Ikiwa utapata udahili,
              maelekezo zaidi yatatolewa kuhusu usajili na mwelekeo
            </p>
          </div>
        </div>
        <div className="col-span-2 font-serif p-5 ">
          <h2 className="text-center md:text-4xl text-2xl text-[#8c7340]">
            Admission Link
          </h2>
          <div className="flex flex-col">
            <div className="hover:translate-x-2 text-blue-950 duration-100">
              {" "}
              <Link to="/admission/info" className="flex gap-2.5 items-center">
                <IoMdArrowForward size={18} />
                How to Apply
              </Link>
            </div>
            <div className="hover:translate-x-2 text-blue-950 duration-100">
              <Link
                to="/admission/programmes"
                className="flex gap-2.5 items-center"
              >
                <IoMdArrowForward size={18} />
                Programmes Offered
              </Link>
            </div>

            <div></div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default AdmissionInfo;
