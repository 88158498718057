import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import AboutUs from "./Pages/AboutUs";


import Adminstration from "./Pages/Adminstration";
import Navbar from "./components/Navbar";
import CampusLife from "./Pages/CampusLife";
import GraceHistory from "./SubPages/GraceHistory";

import AdmissionInfo from "./SubPages/AdmissionInfo";
import Programmes from "./SubPages/Programmes";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navbar />}>
          <Route path="/" element={<Homepage/>} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/about-us/grace" element={<GraceHistory/>} />
           
          
            <Route path="/admission" element={<AdmissionInfo />} />
            <Route path="/admission/info" element={<AdmissionInfo />} />
            <Route path="/admission/programmes" element={<Programmes />} />
     
            <Route path="/campus" element={<CampusLife/>} />
            <Route path="/Adminstration" element={<Adminstration />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
