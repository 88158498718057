import React from 'react'
import image1 from './../Images/hotel2Wide.jpg'
import image2 from './../Images/sports.jpeg'
import image3 from './../Images/imag22.jpg'
import image4 from './../Images/academic.JPG'
import Footer from '../components/Footer'
const CampusLife = () => {
  return (
    <div className='w-full font-sans'>
      <div className='h-52  flex flex-col justify-center items-center'>
        <h2 className='flex justify-center md:text-5xl text-3xl font-bold text-center py-4'>College Life</h2>
        <div className='grid grid-cols-6'>
          <div className='col-span-1'></div>
          <div className='col-span-4'><p className='md:text-3xl text-xl text-center'>Our thriving residential campus is home to a community of creative and accomplished people from around the world.</p></div>
          <div className='col-span-1'></div>
        </div>
      </div>
      <div className='hidden md:block w-full h-96  max-w-[850px] mx-auto '>
          <img src={image1} alt="college Life" loading="lazy" className='h-full w-full object-fill  p-4 md:p-1' />  
      </div>
      <div className='block md:hidden w-full h-full  max-w-[1024px] mx-auto '>
      <img src={image1} alt="" loading="lazy" className='  h-full w-full object-contain  p-4 md:p-1' />
      </div>
       <div className=' pt-12 text-center md:text-5xl text-3xl '>
        student life
       </div>
      <div className='grid md:grid-cols-3  grid-cols-1'>
        <div>
          <div className=''><img src={image2} loading="lazy" alt=""  className='h-72 w-full p-4'/></div>
          <div className='text-center text-xl font-bold'>Sports and Games</div>
          <div className='p-4 '>Grace College offers a variety of sports and recreational activities, including football, volleyball, and more. Students can participate in both competitive leagues and casual games, promoting fitness and teamwork within our vibrant community</div>
        </div>
        <div>
        <div><img src={image3} loading="lazy" alt=""className='h-72 w-full p-4' /></div>
          <div className='text-center text-xl font-bold'>Events and Activities</div>
          <div className='p-4 '>Our college is always buzzing with events like debates, and leadership seminars. These activities provide students with opportunities to connect, learn new skills, and enjoy a lively campus atmosphere</div>
        </div>
        <div>
        <div><img src={image4} loading="lazy" alt="" className='h-72 w-full p-4'/></div>
          <div className='text-center text-xl font-bold'>Academic Experience</div>
          <div className='p-4 '>Enjoy small class sizes and personalized learning at Grace College. Our dedicated faculty, modern facilities, and academic support services ensure you receive a quality education tailored to your needs</div>
        </div>

      </div>
      <div>
        <Footer/>
      </div>
    </div>
  )
}

export default CampusLife