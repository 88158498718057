import React from "react";
import { Link } from "react-router-dom";
import grace from "./../logos/newgrace.png";
import { IoMdArrowDropright } from "react-icons/io";

const Footer = () => {
  return (
    <div className="w-full bg-blue-950  text-white font-sans font-light">
      <div className="grid md:grid-cols-6 grid-col-2 gap-2 text-sm px-4">
        <div className="col-span-2 flex flex-col justify-center items-center py-2">
          <div className=" flex justify-center items-center">
            <img
              src={grace}
              loading="lazy"
              alt="Grace College logo"
              className="w-32 md:w-48"
            />
          </div>
          <div className="p-2 bg-blue-900 my-1 shadow-lg rounded-sm font-bold ">
            Grace College
          </div>
        </div>
        <div className="flex flex-col w-3/4 text-wrap p-2 gap-2">
          <div className="text-2xl font-bold underline pb-2 space-y-2 ">
            Contact
          </div>
          <div>P.O.Box 1745,Mbeya</div>
          <div>Mbeya Mjini</div>
          <div>Tel:(+255)6 79 679 645,</div>
          <div>Tel:(+255)7 42 555 944</div>
          <div>gracecollege2001@gmail.com</div>
        </div>
        <div className="flex flex-col  text-wrap p-2 gap-2">
          <div className="text-2xl font-bold underline pb-2 space-y-2 ">
            Quick Links
          </div>
          <div>
            <Link to="/admission/programmes" className="flex">
              <IoMdArrowDropright /> Programmes Offered
            </Link>
          </div>
          <div>
            <Link to="/admission/info" className="flex">
              <IoMdArrowDropright /> Application Info
            </Link>
          </div>
          <div>
            <Link to="/about-us/grace" className="flex">
              <IoMdArrowDropright /> About Us
            </Link>
          </div>
        </div>
        <div className="flex flex-col  text-wrap p-2 gap-2">
          <div className="text-2xl font-bold underline pb-2 space-y-2">
            Related Links
          </div>
          <div>
            <a href="https://www.nactvet.go.tz/" className="flex">
            <IoMdArrowDropright /> The National Council for Technical and
              Vocational Education and Training (NACTVET)
            </a>
          </div>
          <div>
            <a href="https://www.tcu.go.tz/" className="flex">
              <IoMdArrowDropright /> Tanzania Commission For Universities(TCU)
            </a>
          </div>
          <div>
            <a href="https://portal.ajira.go.tz/" className="flex">
              <IoMdArrowDropright />
              Government Portal
            </a>
          </div>
        </div>
        <div className="flex flex-col  text-wrap p-2 gap-2">
          <div className="text-2xl font-bold underline pb-2 space-y-2">
            Support services
          </div>
          <div>
            <Link to="/">Career services</Link>
          </div>
          <div>
            <Link to="/">Student Support</Link>
          </div>
          <div>
            <Link to="/about-us/grace">IT support</Link>
          </div>
        </div>
      </div>
      <div className="border-t-2 py-4 flex justify-center text-wrap gap-2.5 text-center cursor-pointer">
        <div>privacy policy</div>
        <div>Terms and Condition</div>
        <div> Discramer</div>
        <div>Copyright Statements</div>
      </div>
      <div className="w-full flex justify-center text-xs pb-4 ">
        © 2024 GraceCollege. All rights reserved.
      </div>
      <div className="text-center text-[8px] font-lighter text-slate-400">
      <p> Developed by <a href="#" target="_blank">Mayaya.J</a>.</p>
      </div>
    </div>
  );
};

export default Footer;
