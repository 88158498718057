import React, { useState } from "react";
import { NavLink, Outlet, Link } from "react-router-dom";
import {
  IoIosArrowDown,
  IoMdClose,
  IoMdMail,
  IoMdMap,
  IoMdMenu,
  IoMdMicrophone,
  IoMdPhonePortrait,
} from "react-icons/io";
import { FaMapMarkerAlt } from 'react-icons/fa';
import nembo from "./../logos/NACTVET.png";
import grace from "./../logos/grace2.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [aboutSubNavOpen, setAboutSubNavOpen] = useState(false);
  const [admissionSubNavOpen, setAdmissionSubNavOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const toggleAboutSubNav = () => {
    setAboutSubNavOpen(!aboutSubNavOpen);
    setAdmissionSubNavOpen(false); // Close other sub-menus when this one opens
  };

  const toggleAdmissionSubNav = () => {
    setAdmissionSubNavOpen(!admissionSubNavOpen);
    setAboutSubNavOpen(false); // Close other sub-menus when this one opens
  };

  const handleCloseAll = () => {
    setIsOpen(false);
    setAboutSubNavOpen(false);
    setAdmissionSubNavOpen(false);
  };

  return (
    <div className="relative w-full">
      {/* Desktop Navbar */}
      <div className="relative  hidden md:block bg-[#f6f4f0] shadow-sm">
        <div className="h-8 bg-blue-950 flex text-white gap-4">
          <div className="flex flex-col font-bold  text-xs justify-center items-center">
            <div className="flex">
              <IoMdPhonePortrait size={18} /> +255 742 555 944
            </div>{" "}
          </div>
          <div className="flex font-bold  text-xs justify-center items-center">
          <FaMapMarkerAlt size={18} color="red" />   Mbeya Mjini - Maghorofani nyuma ya Benki Kuu{" "}
          </div>

          <div className="flex font-bold  text-xs justify-center items-center">
            <IoMdMail size={18} /> gracecollege2001@gmail.com
          </div>
        </div>
        <div className="grid grid-cols-6 py-6">
          <div className="col-span-1 flex justify-end items-center">
            <img src={nembo} loading="lazy" alt="Grace College logo" className="w-32" />
          </div>
          <div className="text-center col-span-4 font-serif">
            <div className="text-4xl font-light">
              The United Republic of Tanzania
            </div>
            <div className="text-5xl font-bold text-blue-950">
              Grace College
            </div>
            <div className="text-2xl italic font-light">
              Education for Excellence
            </div>
          </div>

          <div className="col-span-1 flex justify-start items-center">
            <img src={grace} loading="lazy" alt="Nembo logo" className="w-28" />
          </div>
        </div>
      </div>
      <div className="sticky top-0 z-10  hidden   bg-blue-400  w-full md:flex justify-center gap-12 text-white py-1 font-serif">
        <div className="duration-150 shadow-sm rounded-md p-2 hover:translate-x-1">
          <NavLink to="/" onClick={handleCloseAll}>
            Home
          </NavLink>
        </div>

        {/* About Sub-Menu */}
        <div className="relative z-999  duration-150 shadow-sm rounded-md p-2 hover:translate-x-1">
          <NavLink
            to="/about-us"
            className="flex gap-0.5 items-center"
            onClick={toggleAboutSubNav}
          >
            About Grace <IoIosArrowDown size={12} />
          </NavLink>
          {aboutSubNavOpen && (
            <div className="flex flex-col   justify-center  bg-blue-400  border rounded-md shadow-lg fixed overflow-auto top-full left-0 w-60 z-10 gap-2">
              <Link
                to="/about-us"
                onClick={handleCloseAll}
                className="pl-4 py-1 hover:bg-blue-300 border-b "
              >
                Who we Are
              </Link>
              <Link
                to="/about-us/grace"
                onClick={handleCloseAll}
                className="pl-4 py-1 hover:bg-blue-300"
              >
                Background History
              </Link>
            
            </div>
          )}
        </div>

        <div className="duration-150 shadow-sm rounded-md p-2 hover:translate-x-1">
          <NavLink to="/Adminstration" onClick={handleCloseAll}>
            directorate
          </NavLink>
        </div>
        {/* Admission Sub-Menu */}
        <div className="relative hover:bg-blue-400 duration-150 shadow-sm rounded-md p-2 hover:translate-x-1">
          <NavLink
            to="/admission"
            className="flex gap-0.5 items-center"
            onClick={toggleAdmissionSubNav}
          >
            Admission <IoIosArrowDown size={12} />
          </NavLink>
          {admissionSubNavOpen && (
            <div className="flex flex-col bg-blue-400  rounded-md shadow-lg absolute top-full left-0 w-52 z-10">
              <Link
                to="/admission/info"
                onClick={handleCloseAll}
                className="hover:bg-blue-300 p-2 border-b  "
              >
                Admission Info
              </Link>
              <Link
                to="/admission/programmes"
                onClick={handleCloseAll}
                className="p-2 hover:bg-blue-300"
              >
                Programmes Offered
              </Link>
            </div>
          )}
        </div>
        <div className="duration-150 shadow-sm rounded-md p-2 hover:translate-x-1">
          <NavLink to="/campus" onClick={handleCloseAll}>
            College Life
          </NavLink>
        </div>
      
      </div>

      {/* Mobile Navbar */}
      <div className="sticky top-0 z-10 block md:hidden h-16 bg-[#f6f4f0] shadow-sm">
        <div className="w-full flex items-center h-full justify-between">
          <div className="text-xl font-bold  px-4 font-sans flex items-center">
            <img src={grace} loading="lazy" alt="Nembo logo" className="w-16" />

            {/* Grace College */}
          </div>
          <div
            onClick={toggleNav}
            className={`bg-[#ffffff] p-4 ml-4 rounded-full hover:shadow cursor-pointer mr-2 `}
          >
            {isOpen ? (
              <IoMdClose size={25} className="text-[#8c7340]" />
            ) : (
              <IoMdMenu size={25} className="text-[#8c7340]" />
            )}
          </div>
        </div>
        <div
        className={`${
          isOpen ? "flex" : "hidden"
        } flex-col  md:hidden  w-full gap-6 font-serif bg-[#f6f4f0] text-blue-950 h-screen`}
      >
        <div className="w-full border-b duration-150 text-2xl py-4 pl-24">
          <NavLink to="/" onClick={handleCloseAll}>
            Home
          </NavLink>
        </div>

        <div>
          <NavLink
            to="/about-us"
            className="flex gap-4 items-center ease-in-out duration-75 py-2 text-2xl border-b pl-24"
            onClick={toggleAboutSubNav}
          >
            About us
            <IoIosArrowDown
              size={16}
              className={`${aboutSubNavOpen && "rotate-180"} duration-200`}
            />
          </NavLink>
          {aboutSubNavOpen && (
            <div className="flex flex-col w-full">
              <Link
                to="/about-us"
                onClick={handleCloseAll}
                className="pl-24 py-2 hover:bg-gray-100 text-xl border-b bg-white "
              >
                Who We Are
              </Link>
              <Link
                to="/about-us/grace"
                onClick={handleCloseAll}
                className="pl-24 py-2 hover:bg-gray-100 text-xl border-b bg-white "
              >
                Background History
              </Link>
            
            </div>
          )}
        </div>

        <div className="pl-24 border-b duration-150 text-2xl py-2">
          <NavLink to="/Adminstration" onClick={handleCloseAll}>
            directorate
          </NavLink>
        </div>

        <div className=" duration-150" onClick={toggleAdmissionSubNav}>
          <NavLink
            to="/admission"
            className="flex gap-4 pl-24 border-b items-center ease-in-out duration-75 py-2 text-2xl"
          >
            Admission
            <IoIosArrowDown
              size={16}
              className={`${admissionSubNavOpen && "rotate-180"} duration-200`}
            />
          </NavLink>
          {admissionSubNavOpen && (
            <div className="flex flex-col w-full ">
              <Link
                to="/admission/info"
                onClick={handleCloseAll}
                className="pl-24 py-2  text-xl border-b bg-white"
              >
                Admission Info
              </Link>
              <Link
                to="/admission/programmes"
                onClick={handleCloseAll}
                className="pl-24 py-2  text-xl border-b bg-white"
              >
                Programmes Offered
              </Link>
            </div>
          )}
        </div>

        <div className="flex gap-4 pl-24 border-b items-center ease-in-out duration-75 py-2 text-2xl">
          <NavLink to="/campus" onClick={handleCloseAll}>
            College Life
          </NavLink>
        </div>

       
      </div>


      </div>
  

      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default Navbar;
