import React from "react";
import graceImage from "./../Images/graceImage.jpg";
import Footer from "../components/Footer";
import { IoMdArrowDropright } from "react-icons/io";
import { Link } from "react-router-dom";
const GraceHistory = () => {
  return (
    <div className="w-full  font-sans">
      <div className="flex justify-center flex-col items-center w-full h-36 bg-blue-950 text-2xl md:text-4xl shadow-md  text-center  font-sans text-[#8c7340]">
        <h2>background</h2>
        <div className="pt-4 flex text-white text-xs font-light cursor-default">
          <span className="flex justify-center items-center">
            <IoMdArrowDropright />
            <IoMdArrowDropright />
            Background /{" "}
          </span>{" "}
          <span>
            <Link to="/about-us" className="hover:text-blue-600">
              {" "}
              About-us /
            </Link>
          </span>
          <span>
            <Link to="/" className="hover:text-blue-600">
              {" "}
              Home
            </Link>
          </span>
        </div>
      </div>
      <div className="grid md:grid-cols-8 grid-cols-1 gap-2 m-1">
        <div className="col-span-5 bg-[#ffffff] shadow-sm  p-2">
          <h2 className="md:text-5xl text-3xl w-full font-bold py-12 pl-4 ">
            The Grace College History
          </h2>
          <div className="text-justify md:p-2 py-1 p-4">
            <b>Mbeya City</b> is one of the major urban centers in Tanzania,
            located in the southwestern part of the country. Nestled in the
            Mbeya Region, this city is surrounded by stunning natural
            landscapes, including the rolling Mbeya Mountain Range and the lush
            greenery of the surrounding highlands. Mbeya serves as a gateway to
            the southern regions of Tanzania and is a critical hub for both
            commerce and education.
          </div>
          <div className="text-justify md:p-2 py-1 p-4">
            Mbeya sits at an elevation of approximately 1,700 meters (5,577
            feet) above sea level, giving it a cooler climate compared to many
            other parts of Tanzania. The city's highland location provides a
            pleasant environment, with temperatures typically ranging between
            15°C and 25°C (59°F and 77°F).
          </div>
          <div className="text-justify md:p-2 py-1 p-4">
            Mbeya is also recognized as an educational hub in the region,
            hosting several institutions of higher learning, including
            universities, colleges, and vocational training centers.{" "}
            <b>Grace College</b> is one of the notable educational institutions
            in the city, contributing significantly to the intellectual and
            professional development of the youth in the region
          </div>
          <div className="text-justify md:p-2 py-1 p-4">
            <b>Grace College</b> is Non-governmental College established in 2001
            under the Grace Church of Tanzania and registered under the National
            Council for Technical and Vocational Education and Training
            (NACTVET) with registration Number <b>REG/NACTVET/0921P</b>
          </div>

          <div></div>
        </div>
        <div className="col-span-3 p-2 w- h-full flex justify-center items-center">
          <div className="p-2">
            <img
              src={graceImage}
              loading="lazy"
              alt=""
              className="w-full object-fill h-full"
            />
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default GraceHistory;
